<template>
  <div
    class="invoiceInfoSearch"
    v-watermark
  >
    <commonNavBar :title="'开票信息查询'"></commonNavBar>
    <div class="searchBox">
      <form action="/">
        <van-search
          v-model="value"
          show-action
          placeholder="请输入抬头名称"
          @search="onSearch"
          autofocus
        >
          <template #action>
            <div @click="searchBtn">搜索</div>
          </template>
        </van-search>
      </form>
      <!-- 搜索历史 -->
      <div
        class="searchBar"
        v-if="historyList.length"
      >
        <span class="label">搜索历史</span>
        <svg-icon
          icon-class="delet"
          className="myIcon"
          @click="delClick"
        />
      </div>
      <div
        class="searchList"
        v-if="historyList.length"
      >
        <van-cell-group>
          <van-cell
            :title="item"
            v-for="(item, index) in historyList"
            :key="index"
            @click="historyClick(item)"
          />
        </van-cell-group>
      </div>
      <div
        v-else
        class="emptyBox"
      >
        <van-empty
          image="search"
          description="没有任何历史搜索"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import { deleteSearchHistory, getSearchHistory, postSearchHistory } from '@/api/system'

export default {
  name: 'contactSearch',
  data() {
    return {
      value: '',
      historyList: []
    }
  },
  // 路由进入页面
  beforeRouteEnter(to, from, next) {
    if (from.path === '/') {
      next(async (vm) => {
        Object.assign(vm.$data, vm.$options.data.call(vm))
        const { data } = await getSearchHistory({
          searchType: 'invoiceInfoSearchByHead'
        })
        vm.historyList = [...data.data].slice(0, 20)
        vm.$nextTick(() => {
          if (document.querySelector('input')) {
            document.querySelector('input').focus()
          }
        })
      })
    } else {
      next(async (vm) => {
        vm.value = ''
        const { data } = await getSearchHistory({
          searchType: 'invoiceInfoSearchByHead'
        })
        vm.historyList = [...data.data].slice(0, 20)
        vm.$nextTick(() => {
          if (document.querySelector('input')) {
            document.querySelector('input').focus()
          }
        })
      })
    }
  },

  methods: {
    // 删除历史
    delClick() {
      Dialog.confirm({
        title: '删除搜索历史',
        message: '确认清除历史记录？'
      })
        .then(() => {
          this.historyList = []
          deleteSearchHistory({
            searchType: 'invoiceInfoSearchByHead'
          })
        })
        .catch(() => { })
    },

    // 点击搜索按钮
    searchBtn() {
      this.onSearch(this.value)
    },
    // 搜索
    onSearch(val) {
      this.$router.push({
        path: '/workTable/invoiceInfoResult',
        query: {
          text: val
        }
      })
      postSearchHistory({
        searchType: 'invoiceInfoSearchByHead',
        searchTexts: JSON.stringify([val])
      })
    },
    // 点击历史
    historyClick(val) {
      this.$router.push({
        path: '/workTable/invoiceInfoResult',
        query: {
          text: val
        }
      })
      postSearchHistory({
        searchType: 'invoiceInfoSearchByHead',
        searchTexts: JSON.stringify([val])
      })
    },
    // 搜索类型点击
    async typeChange(val) {
      this.value = ''
      this.historyList = []
      const { data } = await getSearchHistory({
        searchType: 'invoiceInfoSearchByHead'
      })
      this.historyList = [...data.data].slice(0, 20)
    }
  }
}
</script>

<style lang="scss" scoped>
.invoiceInfoSearch {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #eeeeee;

  ::v-deep {
    .searchBox {
      height: calc(100% - 46px);
      overflow: auto;

      .van-dropdown-menu {
        height: 100%;
        width: 100%;
        background-color: transparent;
        overflow: hidden;

        .van-dropdown-menu__bar {
          height: 100%;
          width: 100%;
          box-sizing: border-box;
          background-color: transparent;
          box-shadow: none;

          .van-ellipsis {
            padding-right: 5px;
            font-size: 14px;
          }
        }

        .van-dropdown-menu__title::after {
          right: 1px;
        }
      }

      .van-dropdown-item {
        .van-dropdown-item__content {
          .van-dropdown-item__option {
            .van-cell__title {
              padding-left: 25px;
              font-size: 14px;
            }
          }
        }
      }

      .searchBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        height: 24px;

        .label {
          font-size: 12px;
          color: #aaaaaa;
        }

        .myIcon {
          width: 20px;
          height: 20px;
        }
      }

      .emptyBox {
        height: calc(100% - 54px);
        position: relative;

        .van-empty {
          width: 100%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .searchList {
      max-height: calc(100% - 54px - 24px);
      overflow: auto;
      background-color: white;

      .van-cell-group {
        .van-cell {
          min-height: 44px;
        }
      }
    }

    .suggestionList {
      max-height: calc(100% - 54px);
      overflow: auto;
      background-color: white;
    }
  }
}
</style>
